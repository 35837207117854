import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Contrasta el color canyella del centre del capell amb la resta d’ell de color blanc. El capell esdevé pla i fa fins a 6 cm de diàmetre, amb el marge més o menys ondulat i cal dir que pot ser viscós en temps humit i presentar un mamelló central. Les làmines són blanques amb reflexos color carn molt suaus, gruixudes, separades i una mica decurrents. El peu normalment és llarg, prim i ondulat i no sempre està en posició central, encara que també pot ser gruixut i més curt però sempre blanquinós tornant-se crema quan es toca. Les espores són blanques en massa, més o manco el·líptiques, de (6,5) 7,5-9 (10) x (4) 4,5-5 (5,5) micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      